<template>
  <div class="col-md-12">
    <div>
      <h3>{{ titulo }}</h3>
    </div>
    <section class="row component-section">
      <!-- Basic Bootstrap tab example and code -->
      <div class="col-md-12 col-sm-12">
        <div class="component-box">
          <!--Basic Bootstrap tab example -->
          <div>
            <div class="pmd-card">
              <div class="pmd-card-body">
                <!-- Tab panes -->
                <div class="tab-content">
                  <div role="tablist" class="tab-pane active" id="todos">
                    <div class="col-md-4 pull-right">
                      <input type="search" v-model="MySearch" class="form-control" placeholder="Procurar..." />
                      <select class="form-control" id="status-users" v-model="MySearchStatus">
                        <option value=Todos selected>Todos</option>
                        <option value=1>Ativos</option>
                        <option value=0>Inativos</option>
                      </select>
                    </div>

                    <div class="col-md-6">
                      <button data-target="#form-dialog" data-toggle="modal"
                        class="btn pmd-ripple-effect btn-agro pmd-z-depth" type="button" @click.prevent="novo">Novo
                        Usuário</button>

                      <div tabindex="-1" class="modal fade" id="form-dialog" style="display: none;" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header pmd-modal-bordered">
                              <button aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
                              <h2 class="pmd-card-title-text">Cadastro do Usuário</h2>
                            </div>
                            <div class="modal-body">
                              <form class="form-horizontal">
                                <div class="form-group pmd-textfield pmd-textfield" v-if="user.id">
                                  <label for="id">ID</label>
                                  <input type="text" class="mat-input form-control" id="id" v-model="user.id" disabled>
                                </div>
                                <div class="form-group pmd-textfield pmd-textfield">
                                  <label for="name">Nome *</label>
                                  <input type="text" class="mat-input form-control" id="name" v-model="user.name"
                                    required>
                                </div>
                                <div class="form-group pmd-textfield pmd-textfield">
                                  <label for="email">Email *</label>
                                  <input type="text" class="mat-input form-control" id="email" v-model="user.email"
                                    required>
                                </div>
                                <div class="form-group pmd-textfield pmd-textfield" v-if="user.id">
                                  <label for="senha">Senha (8 dígitos)</label>
                                  <input type="text" class="mat-input form-control" id="senha" v-model="user.senha"
                                    required placeholder="Deixe vazio para manter a atual...">
                                </div>
                                <div class="form-group pmd-textfield pmd-textfield">
                                  <label for="mobil">ID GPA *</label>
                                  <input type="text" class="mat-input form-control" id="idgpa" v-model="user.idgpa"
                                    required>
                                  <span class="help-text">Insira o ID do cliente no GPA interno</span>
                                </div>
                                <div class="form-group pmd-textfield pmd-textfield">
                                  <label for="perfil" class="control-label">Perfil *</label>
                                  <select class="form-control" v-model="user.perfil" id="perfil" required>
                                    <option value="cliente">Cliente</option>
                                    <option value="Administrador">Administrador</option>
                                  </select>
                                </div>
                                <div class="form-group pmd-textfield pmd-textfield" v-if="user.id">
                                  <label for="status" class="control-label">Status</label>
                                  <select class="form-control" id="status" v-model="user.status">
                                    <option value=1>Ativo</option>
                                    <option value=0 selected>Inativo</option>
                                  </select>
                                </div>
                                <div class="pmd-modal-action">
                                  <button data-dismiss="modal" class="btn pmd-ripple-effect btn-primary" type="submit"
                                    @click.prevent="salvar"
                                    v-if="user.name && user.email && user.perfil">Salvar</button>
                                  <button data-dismiss="modal" class="btn pmd-ripple-effect btn-default"
                                    type="button">Cancelar</button>
                                </div>
                              </form>
                            </div>

                          </div>
                        </div>
                      </div>

                      <!-- Modal confirma exclusão de usuário -->
                      <div tabindex="-1" class="modal fade" id="form-excluir" style="display: none;" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header pmd-modal-bordered">
                              <button aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
                              <h2 class="pmd-card-title-text">Confirma a exclusão do Usuário?</h2>
                              <h3>{{ user.id }} - {{ user.name }}</h3>
                            </div>
                            <div class="modal-body">
                              <div class="pmd-modal-action">
                                <button data-dismiss="modal" class="btn pmd-ripple-effect btn-error" type="submit"
                                  @click.prevent="excluir(user.id)" v-if="user.id">Excluir</button>
                                <button data-dismiss="modal" class="btn pmd-ripple-effect btn-default"
                                  type="button">Cancelar</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Modal confirma reenvio de email ao usuário -->
                      <div tabindex="-1" class="modal fade" id="form-email" style="display: none;" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header pmd-modal-bordered">
                              <button aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
                              <h2 class="pmd-card-title-text">Confirma o reenvio do email de ativação ao(s) Usuário(s)?
                              </h2>
                              <h3>{{ user.id }} - {{ user.name }} - {{ user.email }}</h3>
                            </div>
                            <div class="modal-body">
                              <div class="pmd-modal-action">
                                <button data-dismiss="modal" class="btn pmd-ripple-effect btn-success" type="submit"
                                  @click.prevent="enviaEmail(user.id, user.name, user.email)"
                                  v-if="user.id && user.email">Enviar E-mail</button>
                                <button data-dismiss="modal" class="btn pmd-ripple-effect btn-success" type="submit"
                                  @click.prevent="enviaEmail(user.id, '', '')" v-if="user.id == 'Todos'">Enviar E-mails
                                  para todos os inativos</button>
                                <button data-dismiss="modal" class="btn pmd-ripple-effect btn-default"
                                  type="button">Cancelar</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="col-md-1">
                      <button data-target="#form-email" data-toggle="modal"
                        class="btn pmd-ripple-effect btn-default pmd-z-depth" type="button"
                        @click.prevent="buscaUser('Todos')">Reenviar e-mail aos Inativos</button>
                    </div>

                    <div class="component-box">
                      <div class="pmd-card pmd-z-depth pmd-card-custom-view">
                        <div class="table-responsive">
                          <table class="table pmd-table table-striped table-mc-red">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>E-mail (login)</th>
                                <th>IDGPA</th>
                                <th>Cliente</th>
                                <th>Perfil</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody v-if="dados.length > 0">
                              <tr v-for="dado in this.dadosTodos">
                                <td>{{ dado.id }}</td>
                                <td>{{ dado.name }}</td>
                                <td>{{ dado.email }}</td>
                                <td>{{ dado.idgpa }}</td>
                                <td>{{ dado.empresa }}</td>
                                <td>{{ dado.perfil }}</td>
                                <td v-if="dado.active == 1"><span class="badge badge-success">Ativo</span></td>
                                <td v-else><span class="badge badge-error">Inativo</span></td>
                                <td><a :href="'#'" @click.prevent="buscaUser(dado.id)" data-target="#form-email"
                                    data-toggle="modal" title="Reenviar e-mail de ativação"><i
                                      class="material-icons md inactive pmd-sm">mail</i></a></td>
                                <td><a :href="'#'" @click.prevent="buscaUser(dado.id)" data-target="#form-dialog"
                                    data-toggle="modal" title="Editar usuário"><i
                                      class="material-icons md inactive pmd-sm">edit</i></a></td>
                                <td>
                                  <a :href="'#'"><i class="material-icons md inactive pmd-sm"
                                      data-target="#form-excluir" data-toggle="modal"
                                      @click.prevent="buscaUser(dado.id)" title="Excluir usuário">delete</i></a>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr></tr>
                              <tr>
                                <td>Sem informações...</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--Basic Bootstrap tab example end-->
        </div>
      </div> <!-- end Basic Bootstrap example and code -->

    </section><!-- end Basic Bootstrap tab -->
  </div>
</template>
<script>
export default {
  props: ["idGpa"],
  data() {
    return {
      titulo: "Administrador / Cadastro de Usuários",
      MySearch: '',
      MySearchStatus: 'Todos',
      dadosTodos: {},
      dadosTodosB: {},
      user: {
        token: sessionStorage.token,
        password: "12345678",
        password_confirmation: "12345678"
      },
      dadosB: {}
    };
  },
  created() {
    let dadosB = {
      token: sessionStorage.token,
      id: ''
    };
    this.$store.dispatch("users", dadosB);
  },
  computed: {
    dados() {
      return this.$store.state.users; 
    },
    dadosUnico() {
      return this.$store.state.userUnico; 
    },
    allDados() {
      /*
      return this.dadosTodos.map((dado) => ({
        ...dado,
        empresa: this.buscaUserUnico(dado.id),
      }));
      */
     return this.$store.state.users;
    },
    newUser() {
      return this.$store.state.newUser;
    },
    userDeletado() {
      return this.$store.state.userDeletado;
    },
    emailEnviado() {
      return this.$store.state.emailEnviado;
    }
  },
  methods: {
    novo() {
      this.user = {};
      this.user.token = sessionStorage.token;
      this.user.password = "12345678";
      this.user.password_confirmation = "12345678";
    },
    salvar() {    
      if(this.user.senha) {
        this.user.password = this.user.senha;
        this.user.password_confirmation = this.user.senha;
      }   
      if(!this.user.name || !this.user.email || !this.user.idgpa || !this.user.perfil) {
        alert("ALERTA! Você precisa preencher todos os campos, usuário não foi criado...");
      } else {
        this.$store.dispatch("newUser", this.user);
      }
    },
    excluir(id) {
      let dadosB = {
        token: sessionStorage.token,
        id: id
      };
      this.$store.dispatch("deleteUser", dadosB);
    },
    enviaEmail(id, name = '', email = '') {
      let dadosB = {
        token: sessionStorage.token,
        id: id,
        name: name,
        email: email
      };
      this.$store.dispatch("enviarEmail", dadosB);
    },
    buscaUser(id) {
      this.user = {};
      this.user.token = sessionStorage.token;
      let dadosB = {
        token: sessionStorage.token,
        id: id
      };
      this.user.id = id;
      if (id != 'Todos') {
        this.$store.dispatch("users", dadosB);        
      } 
      console.log("Id: " + id + " Token: " + this.user.token);
      this.dadosB = dadosB;
    },
    buscaUserUnico(id) {
      this.user = {};
      this.user.token = sessionStorage.token;
      let dadosB = {
        token: sessionStorage.token,
        id: id
      };
      this.$store.dispatch("userUnico", dadosB);
      console.log("Id: " + id + " Empresa: ");
    }
  },
  watch: {
    dados(response) {      
      if(this.dadosB.id) {
        this.user.name = response[0].name;
        this.user.id = this.dadosB.id;
        this.user.email = response[0].email;
        this.user.status = response[0].active;
        this.user.perfil = response[0].perfil;
        this.user.idgpa = response[0].idgpa;
        //console.log("User: " + response[0].name);
      } else {
        this.dadosTodos = response;
      }
    },
    dadosUnico(response) {
      console.log("Empresa: " + response[0].empresa);
    },
    MySearch(response) {
      if (response) {
          this.dadosTodos = this.dadosTodos.filter((element) => {
            return (element.idgpa.toUpperCase().includes(response.toUpperCase()) || element.name.toUpperCase().includes(response.toUpperCase()) || element.email.toUpperCase().includes(response.toUpperCase()))
          })
      } else {
        let dadosB = {
          token: sessionStorage.token,
          id: ''
        };
        this.$store.dispatch("users", dadosB);
        this.dadosTodos = this.dados;
      }      
  },
  MySearchStatus(response) {
    if (response) {
      console.log("Busca status " + response);
      if (response == 0 || response == 1) {
        let dadosB = {
          token: sessionStorage.token,
          id: ''
        };
        this.$store.dispatch("users", dadosB);
        this.dadosTodosB = this.dados;
        setTimeout(() => {
          this.dadosTodos = this.dadosTodosB.filter((element) => {
            return (element.active == response)
          })
        }, 2500);
      } else {
        let dadosB = {
          token: sessionStorage.token,
          id: ''
        };
        this.$store.dispatch("users", dadosB);
        this.dadosTodos = this.dados;
      }
    } else {
      let dadosB = {
        token: sessionStorage.token,
        id: ''
      };
      this.$store.dispatch("users", dadosB);
      this.dadosTodos = this.dados;
    }
  },
    newUser(response) {
      let dadosB = {
        token: sessionStorage.token,
        id: ''
      };
      this.dadosB = dadosB;
      if(this.user.id) {
        alert("Usuário editado com sucesso!");
      } else {
        alert("Usuário criado com sucesso! Ele irá receber um e-mail para ativação...");
      }
      this.user = {};
      this.$store.dispatch("users", dadosB);
      this.user.token = sessionStorage.token;
      this.user.password = "12345678";
      this.user.password_confirmation = "12345678";
    },
    userDeletado(response) {
      let dadosB = {
        token: sessionStorage.token,
        id: ''
      };
      this.$store.dispatch("users", dadosB);
      alert("Usuário excluído! " + response.user.name);
      location.reload();
    },
    emailEnviado(response) {
      let dadosB = {
        token: sessionStorage.token,
        id: ''
      };
      this.$store.dispatch("users", dadosB);
      if (response.email_user) {
        alert("E-mail enviado para " + response.email_user);
      } else {
        alert("E-mail enviado para todos os inativos!");
      }
      location.reload();
    }
  }
};
</script>
<style scoped>
a {
  color: gray;
}
.btn-agro {
  background: #98C140;
  color: white;
}
</style>
