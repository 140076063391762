import Vue from "vue"
import Router from "vue-router"

import Home from "@/components/Home"
import Login from '@/components/Login'

Vue.use(Router)

const routes = [{
        name: 'login',
        path: '/',
        component: Login
    },
    {
        name: 'home',
        path: '/home',
        component: Home
    }
]

const router = new Router({ routes })

export default router