<template>
  <div class="container">
    <section class="row component-section">
      <div class="component-box">
        <div class="pmd-card pmd-z-depth" v-if="this.userLogado.perfil == 'cliente'">
          <div class="pmd-tabs">
            <div class="pull-right" style="color: #143A73;" @click="logout">
              <div style="font-size: larger;" class="btn navbar-brand">Sair</div>
            </div>
            <div class="pull-right" style="color: #143A73;" data-target="#form-dialog" data-toggle="modal">
              <div style="font-size: larger;" class="btn navbar-brand">Alterar meus dados</div>
            </div>

            <div tabindex="-1" class="modal fade" id="form-dialog" style="display: none;" aria-hidden="true">
              <div class="modal-dialog">
								<div class="modal-content">
									<div class="modal-header pmd-modal-bordered">
										<button aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
										<h2 class="pmd-card-title-text">Cadastro do Usuário</h2>
									</div>
									<div class="modal-body">
										<form class="form-horizontal">
                      <div class="form-group pmd-textfield pmd-textfield" v-if="user.id">
								  			<label for="id">ID</label>
												<input type="text" class="mat-input form-control" id="id" v-model="user.id" disabled>
                      </div>
											<div class="form-group pmd-textfield pmd-textfield">
												<label for="name">Nome *</label>
									  		<input type="text" class="mat-input form-control" id="name" v-model="user.name" required>
                      </div>
										  <div class="form-group pmd-textfield pmd-textfield">
												<label for="email">Email *</label>
												<input type="text" class="mat-input form-control" id="email" v-model="user.email" required>
											</div>
                      <div class="form-group pmd-textfield pmd-textfield" v-if="user.id">
												<label for="senha">Senha (8 dígitos)</label>
												<input type="text" class="mat-input form-control" id="senha" v-model="user.senha" required placeholder="Deixe vazio para manter a atual...">
											</div>
                      <div class="pmd-modal-action">
                        <button data-dismiss="modal" class="btn pmd-ripple-effect btn-primary" type="submit" @click.prevent="salvar">Salvar</button>
                        <button data-dismiss="modal" class="btn pmd-ripple-effect btn-default" type="button">Cancelar</button>
                      </div>
										</form>
                  </div>
                </div>
              </div>
            </div>
            <ul class="nav nav-tabs" role="tablist">
              <li role="presentation" class="active">
                <a href="#default" aria-controls="default" role="tab" data-toggle="tab">Início</a>
              </li>
              <li role="presentation">
                <a href="#scrollable" aria-controls="scrollable" role="tab" data-toggle="tab">Financeiro</a>
              </li>
              <li role="presentation">
                <a href="#projects" aria-controls="tab" role="tab" data-toggle="tab">Projetos</a>
              </li>
              <li role="presentation">
                <a href="#fixed" aria-controls="fixed" role="tab" data-toggle="tab">Solicitações</a>
              </li>
            </ul>
          </div>
          <div class="pmd-card-body">
            <div class="tab-content">
              <div role="tabpanel" class="tab-pane active" id="default">
                <Inicio :idGpa="this.idGpa" :cliente="this.cliente"></Inicio>
              </div>
              <div role="tabpanel" class="tab-pane" id="fixed">
                <Solicitacoes :idGpa="this.idGpa"></Solicitacoes>
              </div>
              <div role="tabpanel" class="tab-pane" id="scrollable">
                <Financeiro :idGpa="this.idGpa"></Financeiro>
              </div>
              <div role="tabpanel" class="tab-pane" id="projects">
                <Projetos :idGpa="this.idGpa"></Projetos>
              </div>
            </div>
          </div>
        </div>
        <div class="pull-right" style="color: #143A73;" @click="logout" v-if="this.userLogado.perfil == 'Administrador'">
          <div style="font-size: larger;" class="btn navbar-brand">Sair</div>
        </div>
        <div class="pmd-card pmd-z-depth" v-if="this.userLogado.perfil == 'Administrador'">
          <Users></Users>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Solicitacoes from "./Solicitacoes.vue";
import Inicio from "./Inicio.vue";
import Financeiro from "./Financeiro.vue";
import Projetos from "./Projetos.vue";
import Users from './Users.vue';

export default {
  components: {
    Solicitacoes,
    Inicio,
    Financeiro,
    Projetos,
    Users
  },
  mounted() {
    if(sessionStorage.token) {
      this.token = sessionStorage.token;
    } else {
      this.token = '';
    }
  },
  created() {
    if (!this.token) {
      this.$router.push("/");
    }
    this.$store.dispatch("user", this.token);
    let dados = {
      token: sessionStorage.token,
      idgpa: sessionStorage.idgpa
    };
    this.$store.dispatch("chamados", dados);
  },
  data() {
    return {
      idGpa: this.$store.state.user.idgpa ? this.$store.state.user.idgpa : sessionStorage.idgpa,
      token: this.$store.state.token ? this.$store.state.token : sessionStorage.token,
      cliente: this.$store.state.user.name ? this.$store.state.user.name : sessionStorage.name,
      dados: [],
      boletos: [],
      visitas: [],
      user: {
        token: sessionStorage.token
      },
    };
  },
  methods: {
    logout() {
      console.log("Deslogando");
      this.token = null;
      sessionStorage.clear();
      this.$router.push("/");
    },
    salvar() {    
      if(this.user.senha) {
        this.user.password = this.user.senha;
        this.user.password_confirmation = this.user.senha;
      }   
      if(!this.user.name || !this.user.email) {
        alert("ALERTA! Você precisa preencher todos os campos, usuário não foi criado...");
      } else {
        this.$store.dispatch("newUser", this.user);
      }
    },
  },
  computed: {
    allDados() {
      return this.dados.map((dado) => ({
        ...dado,
        nome: dado.PROJETO.trim() === "" ? "Anonimo" : dado.PROJETO,
      }));
    },
    userLogado() {
      return this.$store.state.user;
    },
    newUser() {
      return this.$store.state.newUser;
    }
  },
  watch: {
    userLogado(response) {
      this.user.name = this.cliente = response.name;
      this.user.email = response.email;
      this.user.id = response.id;
    },
    newUser(response) {      
      alert("Usuário editado com sucesso!");
      this.$store.dispatch("user", this.token);
    },
  },
};
</script>
<style>
@import url("./tab/css/tab.css");
.container {
  margin-top: 70px;
  margin-left: 5%;
}
@media (min-width: 1200px) {
  .container {
    width: 90%;
  }
}
#spa {
  padding: 20px;
}
</style>