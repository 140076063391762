<template>
<div>
    <div class="row" id="default">
        <div class="col-md-12 col-sm-8">
          <div class="media-body">
            <h2 class="text-muted">Seja bem-vindo!</h2>
            <h2 class="text-muted">{{ this.cliente }}</h2>
            <h3 class="text-muted">{{ this.empresa }}</h3>
          </div>
          <div class="media-body">
            <center><h1 class="text-muted"><a href="https://api.whatsapp.com/send?phone=54996448746" target="_blank"><img src="whatsapp.png" alt="whats" width="80"></a></h1>
            <h3 class="text-muted"><b>Contatar Suporte</b></h3></center>
          </div>
        </div>
    </div>
    <div class="row" id="default">
      <div class="col-md-2 col-sm-8">
      </div>
      <div class="col-md-12 col-sm-8">
          <div class="pmd-card pmd-z-depth"></div>
      </div>
      <div class="col-md-12 col-sm-8" v-if="boletos.length > 0">
        <h3 class="text-muted"><b><i class="material-icons media-left">monetization_on</i>Boletos em Aberto</b></h3>
        <div class="pmd-card pmd-z-depth" v-if="boletos.length <= 0" id="spa">
          <span>Sem Informações</span>
        </div>
        <div class="pmd-card pmd-z-depth" v-else>
          <ul class="list-group pmd-list pmd-card-list" v-for="dado in boletos" v-if="dado.STATUS !== 'Quitada'">
            <li class="list-group-item">
              <div class="media-body">
                <div :class='dado.SITUACAO == "No Prazo" ? "badge badge-success" : (dado.SITUACAO == "Em Atraso" ? "badge badge-error" : "")'>{{ dado.SITUACAO == "Em Atraso" ? "Vencida" : dado.STATUS }}
                </div>
                Nº da NF <b>{{ dado.NR_DOCUMENTO }} </b>
                Data de Emissão: <b>{{ dado.DT_EMISSAO }}</b>
                Data de Vencimento: <b>{{ dado.DATA_VENCIMENTO }}</b>
                Valor: <b>R$ {{ dado.VL_PARCELA }}</b>
                <!-- <a href="#"><i class="material-icons media-left">file_download</i></a> -->
              </div>
              <div class="media-body">
                                
                <!-- <a href="#"><i class="material-icons media-left">file_download</i></a> -->
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 col-sm-6" v-if="visitas.length > 0">
        <h3 class="text-muted"><b><i class="material-icons media-left">content_paste</i>Visitas agendadas em projetos</b></h3>
         <div class="pmd-card pmd-z-depth" v-if="visitas.length <= 0" id="spa">
          <span>Sem Visitas</span>
        </div>
        <div class="pmd-card pmd-z-depth" v-else>
          <ul class="list-group pmd-list pmd-card-list" v-for="(dado, index) in visitas" v-bind:key="index">
            <li class="list-group-item">              
              <div class="media-body">
                <b>Data prevista: {{ dado.DATAPREVISTAVISITA }}</b>
                Projeto: {{ dado.PROJETO }} 
                Consultor: {{ dado.CONSULTORRESPONSAVEL }}<br>
                Tipo: {{ dado.TIPOATENDIMENTO }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- Visitas para aprovar -->
      <div class="col-md-6 col-sm-6" v-if="visitasAprovar.length > 0">
        <h3 class="text-muted"><b><i class="material-icons media-left">error_outline</i>Visitas realizadas em projetos pendentes de aprovação</b></h3>
         <div class="pmd-card pmd-z-depth" v-if="visitasAprovar.length <= 0" id="spa">
          <span>Sem Visitas</span>
        </div>
        <div class="pmd-card pmd-z-depth" v-else>
          <ul class="list-group pmd-list pmd-card-list" v-for="(dado, index) in visitasAprovar" v-bind:key="index">
            <li class="list-group-item">              
              <div class="media-body">
                <b>Data Realização: {{ dado.DATAREALIZACAOVISITA }}</b>
                Projeto: {{ dado.PROJETO }}
                Consultor: {{ dado.CONSULTORRESPONSAVEL }}<br>
                Tipo: {{ dado.TIPOATENDIMENTO }} | 
                <a :href='dado.URL' target="_blank">Acessar Link para Aprovar</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      
    </div>
</div>
</template>
<script>

export default ({
    props: ['idGpa', 'cliente'],
    data() {
        return {
          titulo: 'Lista das Solicitações',
          dados: [],
          solicitacoes: [],
          notas: [],
          visitas: [],
          visitasAprovar: [],
          empresa: null
        }
    },
    created() {
      let dados = {
        token: sessionStorage.token,
        idgpa: sessionStorage.idgpa
      };
      this.$store.dispatch("boletos", dados);

      let dadosB = {
        token: sessionStorage.token,
        id: sessionStorage.idUser
      };
      this.$store.dispatch("users", dadosB);

      axios
      .get('https://app.plataformasintonia.com/api/api/v1/projetosagros/list/null/' + dados.idgpa)
      .then(response => (this.dados = response.data))
      .catch(error => console.log("Error " + error))
      /*
      axios
      .get('http://localhost/agro1/db_firebird.php/',
      {
        withCredentials: false
      })
      .then(response => (this.solicitacoes = response.data))
      .catch(error => console.log("Error API Solicitacoes " + error + ' Response ' + response))
      */
    },
    computed: {
      allDados() {
        return this.dados.map(dado => ({
          ...dado,
          tokenShared: dado.shareds ? dado.shareds.TOKEN : 'não'
        }))
      },
      allVisitasApr() {
        return this.dados;
      },
      boletos() {
        return this.$store.state.boletos;
      },
      dadosEmpresa() {
        return this.$store.state.users;
      }
    },
    watch: {
      dadosEmpresa(response) {
        this.empresa = response[0].empresa
      },
      allDados(response) {
        response.forEach(element => {
          if (element.proxVisitas) {
            element.proxVisitas.forEach(e => {
            })
          }
        });
        
        let visitas = [];
        response.filter(element => element.proxVisitas.length > 0).forEach(element => {
          element.proxVisitas.forEach(e => {
            visitas.push(e)
          })
        });
        this.visitas = visitas;
      },
      allVisitasApr(response) {
        let visitasAprovar = [];
        response.filter(element => element.visitasAprovar.length > 0).forEach(element => {
          element.visitasAprovar.forEach(e => {
            visitasAprovar.push(e)
          })
        });
        console.log("Visitas aprovar: " + visitasAprovar);
        this.visitasAprovar = visitasAprovar;
      }
  }
})
</script>
<style scoped>
.material-icons.media-left {
    display: inline;
}
</style>