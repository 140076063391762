<template>
  <div class="col-md-12">
    <div>
      <h3>{{ titulo }}</h3>
    </div>
    <div class="component-box">
      <div class="pmd-card pmd-z-depth pmd-card-custom-view">
        <div class="table-responsive">
          <table class="table pmd-table table-striped table-mc-red">
            <thead>
              <tr>
                <th>Projeto</th>
                <th>Produto</th>
                <!--<th>Data Aceite</th>-->
                <th>Horas Contratadas</th>
                <th>Horas Executadas</th>
                <th>Saldo de Horas</th>
                <th>Situação</th>
                <th></th>               
              </tr>
            </thead>
            <tbody v-if="dados.length > 0">
              <tr v-for="dado in dados" v-if="dado.PROJETO != '---(---)' && dado.STATUS_PROJETO != 'Cancelado'">
                <td>{{ dado.PROJETO }}</td>
                <td>{{ dado.PRODUTO_LABEL }}</td>
                <!--<td>{{ dado.DATA_ACEITE_PROJ }}</td>-->
                <td><span class="badge badge-success">{{ dado.HORAS_CONTRATADAS }}</span></td>
                <td><span class="badge badge-warning">{{ dado.HORAS_PROJETO }}</span></td>
                <td><span class="badge badge-info">{{ dado.HORAS_SALDO }}</span></td>
                <td>{{ dado.STATUS_PROJETO }}</td>
                <td><a :href='"https://plataformasintonia.com/agro1_projeto_page/" + dado._id + "?acesso=cliente"' target="_blank">Acessar</a></td>            
              </tr>
            </tbody>
            <tbody v-else>z
              <tr></tr>
              <tr>
                <td>Sem informações...</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="col-md-6 col-sm-6">
          <h3 class="text-muted"><b><i class="material-icons media-left">content_paste</i>Visitas agendadas em projetos</b></h3>
          <div class="pmd-card pmd-z-depth" v-if="visitas.length <= 0" id="spa">
            <span>Sem Visitas</span>
          </div>
          <div class="pmd-card pmd-z-depth" v-else>
            <ul class="list-group pmd-list pmd-card-list" v-for="dado in visitas">
              <li class="list-group-item">              
                <div class="media-body">
                  <b>Data prevista: {{ new Date(dado.data_prevista).toLocaleString().split(',')[0] }}</b>
                  Projeto: {{ dado.projeto_label }} 
                  Consultor: {{ dado.responsavel_label }}<br>
                  Tipo: {{ dado.tipo_atendimeto }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- Visitas para aprovar -->
        <div class="col-md-6 col-sm-6">
          <h3 class="text-muted"><b><i class="material-icons media-left">error_outline</i>Visitas realizadas em projetos pendentes de aprovação</b></h3>
          <div class="pmd-card pmd-z-depth" v-if="visitasAprovar.length <= 0" id="spa">
            <span>Sem Visitas</span>
          </div>
          <div class="pmd-card pmd-z-depth" v-else>
            <ul class="list-group pmd-list pmd-card-list" v-for="dado in visitasAprovar">
              <li class="list-group-item">              
                <div class="media-body">
                  <b>Data Realização: {{ new Date(dado.DATAREALIZACAOVISITA).toLocaleString().split(',')[0] }}</b>
                  Projeto: {{ dado.projeto_label }}
                  Consultor: {{ dado.responsavel_label }}<br>
                  Tipo: {{ dado.tipo_atendimeto }} | 
                  <a :href='"https://plataformasintonia.com/agro1_atividade_executar/" + dado._id + "?id=relatorio"' target="_blank">Acessar Link para Aprovar</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["idGpa"],
  data() {
    return {
      titulo: "Projetos de Implementação",
      dados: [],
      shareds: [],
      visitas: [/*{
             PROJETO: '1937(OPT-NEW-TESTE) ',
             DATA_VISITA: '01/07/2021',
             CONSULTOR: 'Joao da Silva',
             TIPO_VISITA: 'Presencial'
           }*/],
      visitasAprovar: []
    };
  },
  created() {
    this.$store.dispatch("projetos", sessionStorage.idgpa);
    /*
    axios
      .get('https://dev.plataformasintonia.com/api/api/v1/projetosagros/list/701')
      .then(response => (this.dados = response.data))
      .catch(error => console.log("Error API " + error));
      */
  },
  computed: {
    allDados() {
      return this.$store.state.projetos;

      /*
      return this.dados.map(dado => ({
        ...dado,
        tokenShared: dado.shareds ? dado.shareds.TOKEN : 'não'
      }));
      */      
    },
    allVisitasApr() {
      return this.dados;
    }
  },
  watch: {
    allDados(response) {
      /*this.dados = response.map(dado => ({
        ...dado,
        tokenShared: dado.shareds ? dado.shareds.TOKEN : 'não'
      }));
      */
      this.dados = response.response.projetos;
      /*
      response.forEach(element => {
        if (element.proxVisitas) {
          element.proxVisitas.forEach(e => {
            //console.log('data : ' + e.DATAPREVISTAVISITA);
          })
        }
      });
      */
      
      /*let visitas = [];
      response.filter(element => element.proxVisitas.length > 0).forEach(element => {
        element.proxVisitas.forEach(e => {
          visitas.push(e)
        })
      });
      */
      //this.visitas = visitas;
      this.visitas = response.response.visitas_agendadas;
      this.visitasAprovar = response.response.visitas_aprovacao;
    },
    allVisitasApr(response) {
      /*let visitasAprovar = [];
      response.filter(element => element.visitasAprovar.length > 0).forEach(element => {
        element.visitasAprovar.forEach(e => {
          visitasAprovar.push(e)
        })
      });
      */
      //this.visitasAprovar = response.response.visitas_aprovacao;
    }
  }
};
</script>