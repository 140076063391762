<template>
  <div class="col-md-12">
    <div>
      <h3>{{ titulo }}</h3>
    </div>
    <section class="row component-section">			
			<!-- Basic Bootstrap tab example and code -->
			<div class="col-md-12 col-sm-12"> 
				<div class="component-box">
					<!--Basic Bootstrap tab example -->
					<div> 
						<!-- Nav tabs -->
						<ul class="nav nav-tabs" role="tablist">
              <li role="presentation" class="active"><a href="#todos" aria-controls="home" role="tab" data-toggle="tab">Todas</a></li>
              <!--<li role="presentation"><a href="#AguardandoRetornoResponsavel" aria-controls="work" role="tab" data-toggle="tab">Aguardando Retorno Responsável</a></li>-->
              <li role="presentation"><a href="#AguardandoAprovacao" aria-controls="about" role="tab" data-toggle="tab">Aguardando Aprovação</a></li>
              <li role="presentation"><a href="#Aprovada" aria-controls="work" role="tab" data-toggle="tab">Aprovadas</a></li>
              <li role="presentation"><a href="#Analisada" aria-controls="work" role="tab" data-toggle="tab">Analisadas - Aguardando Versão</a></li>
							<li role="presentation"><a href="#EmDesenvolvimento" aria-controls="home" role="tab" data-toggle="tab">Em Desenvolvimento</a></li>
              <li role="presentation"><a href="#Concluida" aria-controls="work" role="tab" data-toggle="tab">Concluídas</a></li>
              <li role="presentation"><a href="#Recusada" aria-controls="work" role="tab" data-toggle="tab">Recusados</a></li>
						</ul>
						<div class="pmd-card">
							<div class="pmd-card-body">
								<!-- Tab panes -->
								<div class="tab-content">
                  <div role="tablist" class="tab-pane active" id="todos">
                    <div class="col-md-4 pull-right">
                      <input type="search" v-model="MySearch" class="form-control" placeholder="Procurar pelo nº, assunto, produto ou data de registro..." />
                    </div>
                    <div class="component-box">
                      <div class="pmd-card pmd-z-depth pmd-card-custom-view">
                        <div class="table-responsive">
                          <table class="table pmd-table table-striped table-mc-red">
                            <thead>
                              <tr>
                                <th>Nº</th>
                                <th>Status</th>
                                <th style="min-width: 120px;">Data de Registro</th>
                                <!--<th>Previsão</th>-->
                                <th>Assunto</th>
                                <th>Produto</th>
                                
                                <th>Resp. Agro1</th>
                                <th>Resp. Cliente</th>
                              </tr>
                            </thead>
                            <tbody v-if="solicitacoes.length > 0">
                              <tr v-for="dado in this.solicitacoesTodas">
                                <td>{{ dado.IDSOLICITACAO }}</td>
                                <td>{{ dado.STATUS }}</td>
                                <td>{{ dado.DATA }}</td>
                                <!--<td>{{ dado.DTPREVISAOENTREGA }}</td>-->
                                <td>{{ dado.DESCRICAO_RESUMIDA }}</td>
                                <td>{{ dado.PRODUTO }}</td>
                                
                                <td>{{ dado.RESPONSAVEL_AGRO1 }}</td>
                                <td>{{ dado.RESPONSAVEL_CLIENTE }}</td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr></tr>
                              <tr>
                                <td>Sem informações...</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--
                  <div role="tablist" class="tab-pane" id="AguardandoRetornoResponsavel">
                    <div class="component-box">
                      <div class="pmd-card pmd-z-depth pmd-card-custom-view">
                        <div class="table-responsive">
                          <table class="table pmd-table table-striped table-mc-red">
                            <thead>
                              <tr>
                                <th>Nº</th>
                                <th>Criação</th>
                                <th>Previsão</th>
                                <th>Assunto</th>
                                <th>Produto</th>
                                <th>Status</th>
                                <th>Motivo Recusa</th>
                                <th>Resp. Agro1</th>
                                <th>Resp. Cliente</th>
                              </tr>
                            </thead>
                            <tbody v-if="solicitacoes.length > 0">
                              <tr v-for="dado in solicitacoes" v-if="dado.STATUS == 'Aguardando Retorno Responsável'">
                                <td>{{ dado.IDSOLICITACAO }}</td>
                                <td>{{ dado.DATA }}</td>
                                <td>{{ dado.DTPREVISAOENTREGA }}</td>
                                <td>{{ dado.DESCRICAO_RESUMIDA }}</td>
                                <td>{{ dado.PRODUTO }}</td>
                                <td>{{ dado.STATUS }}</td>
                                <td>{{ dado.MOTIVORECUSA }}</td>
                                <td>{{ dado.RESPONSAVEL_AGRO1 }}</td>
                                <td>{{ dado.RESPONSAVEL_CLIENTE }}</td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr></tr>
                              <tr>
                                <td>Sem informações...</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  -->
                  <div role="tablist" class="tab-pane" id="AguardandoAprovacao">
                    <div class="col-md-4 pull-right">
                      <input type="search" v-model="MySearch" class="form-control" placeholder="Procurar pelo nº, assunto, produto ou data de registro..." />
                    </div>
                    <div class="component-box">
                      <div class="pmd-card pmd-z-depth pmd-card-custom-view">
                        <div class="table-responsive">
                          <table class="table pmd-table table-striped table-mc-red">
                            <thead>
                              <tr>
                                <th>Nº</th>
                                <th style="min-width: 120px;">Data de Registro</th>
                                <!--<th>Previsão</th>-->
                                <th>Assunto</th>
                                <th>Produto</th>
                                <th>Status</th>
                                
                                <th>Resp. Agro1</th>
                                <th>Resp. Cliente</th>
                              </tr>
                            </thead>
                            <tbody v-if="solicitacoes.length > 0">
                              <tr v-for="dado in solicitacoesTodas" v-if="dado.STATUS == 'Aguardando Aprovação'">
                                <td>{{ dado.IDSOLICITACAO }}</td>
                                <td>{{ dado.DATA }}</td>
                                <!--<td>{{ dado.DTPREVISAOENTREGA }}</td>-->
                                <td>{{ dado.DESCRICAO_RESUMIDA }}</td>
                                <td>{{ dado.PRODUTO }}</td>
                                <td>{{ dado.STATUS }}</td>
                                
                                <td>{{ dado.RESPONSAVEL_AGRO1 }}</td>
                                <td>{{ dado.RESPONSAVEL_CLIENTE }}</td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr></tr>
                              <tr>
                                <td>Sem informações...</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div role="tablist" class="tab-pane" id="Aprovada">
                    <div class="col-md-4 pull-right">
                      <input type="search" v-model="MySearch" class="form-control" placeholder="Procurar pelo nº, assunto, produto ou data de registro..." />
                    </div>
                    <div class="component-box">
                      <div class="pmd-card pmd-z-depth pmd-card-custom-view">
                        <div class="table-responsive">
                          <table class="table pmd-table table-striped table-mc-red">
                            <thead>
                              <tr>
                                <th>Nº</th>
                                <th style="min-width: 120px;">Data de Registro</th>
                                <!--<th>Previsão</th>-->
                                <th>Assunto</th>
                                <th>Produto</th>
                                <th>Status</th>
                                
                                <th>Resp. Agro1</th>
                                <th>Resp. Cliente</th>
                              </tr>
                            </thead>
                            <tbody v-if="solicitacoes.length > 0">
                              <tr v-for="dado in solicitacoesTodas" v-if="dado.STATUS == 'Aprovada'">
                                <td>{{ dado.IDSOLICITACAO }}</td>
                                <td>{{ dado.DATA }}</td>
                                <!--<td>{{ dado.DTPREVISAOENTREGA }}</td>-->
                                <td>{{ dado.DESCRICAO_RESUMIDA }}</td>
                                <td>{{ dado.PRODUTO }}</td>
                                <td>{{ dado.STATUS }}</td>
                                
                                <td>{{ dado.RESPONSAVEL_AGRO1 }}</td>
                                <td>{{ dado.RESPONSAVEL_CLIENTE }}</td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr></tr>
                              <tr>
                                <td>Sem informações...</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div role="tablist" class="tab-pane" id="Analisada">
                    <div class="col-md-4 pull-right">
                      <input type="search" v-model="MySearch" class="form-control" placeholder="Procurar pelo nº, assunto, produto ou data de registro..." />
                    </div>
                    <div class="component-box">
                      <div class="pmd-card pmd-z-depth pmd-card-custom-view">
                        <div class="table-responsive">
                          <table class="table pmd-table table-striped table-mc-red">
                            <thead>
                              <tr>
                                <th>Nº</th>
                                <th style="min-width: 120px;">Data de Registro</th>
                                <!--<th>Previsão</th>-->
                                <th>Assunto</th>
                                <th>Produto</th>
                                <th>Status</th>
                                
                                <th>Resp. Agro1</th>
                                <th>Resp. Cliente</th>
                              </tr>
                            </thead>
                            <tbody v-if="solicitacoes.length > 0">
                              <tr v-for="dado in solicitacoesTodas" v-if="dado.STATUS == 'Analisada - Aguardando Versão'">
                                <td>{{ dado.IDSOLICITACAO }}</td>
                                <td>{{ dado.DATA }}</td>
                                <!--<td>{{ dado.DTPREVISAOENTREGA }}</td>-->
                                <td>{{ dado.DESCRICAO_RESUMIDA }}</td>
                                <td>{{ dado.PRODUTO }}</td>
                                <td>{{ dado.STATUS }}</td>
                            
                                <td>{{ dado.RESPONSAVEL_AGRO1 }}</td>
                                <td>{{ dado.RESPONSAVEL_CLIENTE }}</td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr></tr>
                              <tr>
                                <td>Sem informações...</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
									<div role="tablist" class="tab-pane" id="EmDesenvolvimento">
                    <div class="col-md-4 pull-right">
                      <input type="search" v-model="MySearch" class="form-control" placeholder="Procurar pelo nº, assunto, produto ou data de registro..." />
                    </div>
                    <div class="component-box">
                      <div class="pmd-card pmd-z-depth pmd-card-custom-view">
                        <div class="table-responsive">
                          <table class="table pmd-table table-striped table-mc-red">
                            <thead>
                              <tr>
                                <th>Nº</th>
                                <th style="min-width: 120px;">Data de Registro</th>
                                <!--<th>Previsão</th>-->
                                <th>Assunto</th>
                                <th>Produto</th>
                                <th>Status</th>
                                
                                <th>Resp. Agro1</th>
                                <th>Resp. Cliente</th>
                              </tr>
                            </thead>
                            <tbody v-if="solicitacoes.length > 0">
                              <tr v-for="dado in solicitacoesTodas" v-if="dado.STATUS == 'Em Desenvolvimento'">
                                <td>{{ dado.IDSOLICITACAO }}</td>
                                <td>{{ dado.DATA }}</td>
                                <!--<td>{{ dado.DTPREVISAOENTREGA }}</td>-->
                                <td>{{ dado.DESCRICAO_RESUMIDA }}</td>
                                <td>{{ dado.PRODUTO }}</td>
                                <td>{{ dado.STATUS }}</td>
                                
                                <td>{{ dado.RESPONSAVEL_AGRO1 }}</td>
                                <td>{{ dado.RESPONSAVEL_CLIENTE }}</td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr></tr>
                              <tr>
                                <td>Sem informações...</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div role="tablist" class="tab-pane" id="Concluida">
                    <div class="col-md-4 pull-right">
                      <input type="search" v-model="MySearch" class="form-control" placeholder="Procurar pelo nº, assunto, produto ou data de registro..." />
                    </div>
                    <div class="component-box">
                      <div class="pmd-card pmd-z-depth pmd-card-custom-view">
                        <div class="table-responsive">
                          <table class="table pmd-table table-striped table-mc-red">
                            <thead>
                              <tr>
                                <th>Nº</th>
                                <th style="min-width: 120px;">Data de Registro</th>
                                <!--<th>Previsão</th>-->
                                <th>Assunto</th>
                                <th>Produto</th>
                                <th>Versão</th>
                                <th>Status</th>                                
                                <th>Resp. Agro1</th>
                                <th>Resp. Cliente</th>
                              </tr>
                            </thead>
                            <tbody v-if="solicitacoes.length > 0">
                              <tr v-for="dado in solicitacoesTodas" v-if="dado.STATUS == 'Concluída'">
                                <td>{{ dado.IDSOLICITACAO }}</td>
                                <td>{{ dado.DATA }}</td>
                                <!--<td>{{ dado.DTPREVISAOENTREGA }}</td>-->
                                <td>{{ dado.DESCRICAO_RESUMIDA }}</td>
                                <td>{{ dado.PRODUTO }}</td>
                                <td>{{ dado.VERSAO }}</td>
                                <td>{{ dado.STATUS }}</td>
                                
                                <td>{{ dado.RESPONSAVEL_AGRO1 }}</td>
                                <td>{{ dado.RESPONSAVEL_CLIENTE }}</td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr></tr>
                              <tr>
                                <td>Sem informações...</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div role="tablist" class="tab-pane" id="Recusada">
                    <div class="col-md-4 pull-right">
                      <input type="search" v-model="MySearch" class="form-control" placeholder="Procurar pelo nº, assunto, produto ou data de registro..." />
                    </div>
                    <div class="component-box">
                      <div class="pmd-card pmd-z-depth pmd-card-custom-view">
                        <div class="table-responsive">
                          <table class="table pmd-table table-striped table-mc-red">
                            <thead>
                              <tr>
                                <th>Nº</th>
                                <th style="min-width: 120px;">Data de Registro</th>
                                <!--<th>Previsão</th>-->
                                <th>Assunto</th>
                                <th>Produto</th>
                                <th>Status</th>
                                <th>Motivo Recusa</th>
                                <th>Resp. Agro1</th>
                                <th>Resp. Cliente</th>
                              </tr>
                            </thead>
                            <tbody v-if="solicitacoes.length > 0">
                              <tr v-for="dado in solicitacoesTodas" v-if="dado.STATUS == 'Recusada'">
                                <td>{{ dado.IDSOLICITACAO }}</td>
                                <td>{{ dado.DATA }}</td>
                                <!--<td>{{ dado.DTPREVISAOENTREGA }}</td>-->
                                <td>{{ dado.DESCRICAO_RESUMIDA }}</td>
                                <td>{{ dado.PRODUTO }}</td>
                                <td>{{ dado.STATUS }}</td>
                                <td>{{ dado.MOTIVORECUSA }}</td>
                                <td>{{ dado.RESPONSAVEL_AGRO1 }}</td>
                                <td>{{ dado.RESPONSAVEL_CLIENTE }}</td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr></tr>
                              <tr>
                                <td>Sem informações...</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
								</div>
							</div>
						</div>
					</div> <!--Basic Bootstrap tab example end-->
					
				</div>
			</div> <!-- end Basic Bootstrap example and code -->
			
		</section><!-- end Basic Bootstrap tab --> 
  </div>
</template>
<script>
export default {
  props: ["idGpa"],
  data() {
    return {
      titulo: "Lista de Solicitações",
      MySearch: '',
      solicitacoesTodas: {},
    };
  },
  created() {
    let dados = {
      token: sessionStorage.token,
      idgpa: sessionStorage.idgpa
    };
    this.$store.dispatch("chamados", dados);
    /*
    axios
      .get("http://localhost/agro1/db_firebird.php?idgpa=" + this.idGpa, {
        withCredentials: false,
      })
      .then((response) => (this.solicitacoes = response.data))
      .catch((error) => console.log('Ops.. ' + error + ' Response: '));
      */
  },
  computed: {
    solicitacoes() {
      return this.$store.state.chamados;
    }
  },
  watch: {
    solicitacoes(response) {
      this.solicitacoesTodas = response;
    },
    MySearch(response) {
      if (response) {
        this.solicitacoesTodas = this.solicitacoesTodas.filter((element) => {
          return (element.IDSOLICITACAO.toString().includes(response) || element.DESCRICAO_RESUMIDA.toUpperCase().includes(response.toUpperCase()) || element.DATA.toString().includes(response) || element.PRODUTO.toUpperCase().includes(response.toUpperCase()))
        })
      } else {
        this.solicitacoesTodas = this.solicitacoes;
      }
      
    }
  }
};
</script>
<style scoped>
a {
  color: gray;
}
.col-md-12, .col-sm-12 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
