<template lang="html">
  <div class="logincard">
    <div class="pmd-card card-default pmd-z-depth">
      <div class="box">
        <form @submit.prevent="login">
          <div class="pmd-card-title card-header-border text-center">
            <div class="loginlogo">
              <!-- <a href="javascript:void(0);"><img src="./../../public/logo.png" alt="Logo"></a> -->
              <span><h2>Entre com seu usuário e senha!</h2></span>
            </div>
          </div>

          <div class="pmd-card-body">
            <div class="form-group pmd-textfield">
              <label class="control-label pmd-input-group-label">E-mail</label>
              <div class="input-group">
                <div class="input-group-addon"><i class="material-icons md-dark pmd-sm">perm_identity</i></div>
                <input type="mail" class="form-control" v-model="user.email"><span class="pmd-textfield-focused"></span>
              </div>
            </div>

            <div class="form-group pmd-textfield">
              <label class="control-label pmd-input-group-label">Senha</label>
              <div class="input-group">
                <div class="input-group-addon"><i class="material-icons md-dark pmd-sm">lock_outline</i></div>
                <input type="password" class="form-control" v-model="user.password"><span class="pmd-textfield-focused"></span>
              </div>
            </div>
          </div>

          <div class="pmd-card-footer card-footer-no-border card-footer-p16 text-center">
            <div class="form-group clearfix">
              <span class="pull-right forgot-password">
                <a href="https://plataformasintonia.com/portal_agro1_reset_senha">Esqueci minha senha</a>
              </span>
            </div>

            <button type="submit" name="button" class="btn pmd-ripple-effect btn-block mb10">Entrar</button>
          </div>
          <div class="pmd-card-footer card-footer-no-border card-footer-p16 text-center">
            <div class="form-group clearfix">
              <span class="error-login">
                {{ this.alert }}
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: "login",
  data() {
    return {
      user: {},
      alert: '',
      dados: {},
    };
  },
  created() {
    if (sessionStorage.token) {
      this.$router.push("/home");
    }
    this.$store.state.token = ''
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userLogado() {
      return this.$store.state.user;
    }
  },
  methods: {
    login() {
      if (this.user.email && this.user.password) {
        let dados = {
          email: this.user.email,
          password: this.user.password
        };
        this.alert = 'Aguarde...';
        this.$store.dispatch("login", dados);
        let t = this;
        setTimeout(function() {
          if (t.$store.state.token) {
            t.alert = 'Logado!';
            //t.$router.push("/home");
          } else {
            t.alert = "Erro de login! Verifique seu usuário e senha...";
          }
        }, 7000);
      } else {
        this.alert = "Insira usuário e senha!";
      }
      
      /*
      this.$store.dispatch("login", dados).then((response) => {
        if (response) {
          this.alert = 'Logado!';
          this.$router.push("/home");
        } else {
          this.alert = "Erro de login! Verifique seu usuário e senha...";
        }
      });
      */

      /*
      axios
      .post('http://localhost:8000/api/auth/login', dados,
      {
        withCredentials: false
      })
      .then(response => (
        this.alert = response.data.token,
        this.$router.push("/home")
      ))
      .catch(error => (this.alert = "Erro de login! Verifique seu usuário e senha..."));
      */


    }
  },
  watch: {
    token(response) {
      this.alert = 'Logando...';
      this.$router.push("/home");
      //localStorage.token = response;
      sessionStorage.token = response;
      sessionStorage.name = this.$store.state.user.name;
      sessionStorage.idgpa = this.$store.state.user.idgpa;
      sessionStorage.idUser = this.$store.state.user.id;
    }
  }
}
</script>

<style lang="css">
.mb10 {
  margin-bottom: 20px;
  background-color: #143A73;
  color: whitesmoke;
}
.logincard {
  transform: translate(0px, 20%);
  width: 100vw;
  height: 75vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.box {
  width: 450px;
  height: 480px;
  background: #fff;
}
.loginlogo {
  padding: 15px;
}
.error-login {
  color: red;
  font-size: x-large;
}
</style>