<template>
  <div class="col-md-12">
    <div>
      <h3>{{ titulo }}</h3>
    </div>
    <div class="component-box">
      <div class="pmd-card pmd-z-depth pmd-card-custom-view">
        <div class="table-responsive">
          <table class="table pmd-table table-striped table-mc-red">
            <thead>
              <tr>
                <th>Nota Fiscal Nº</th>
                <th>Data Emissão</th>
                <th>Data Vencimento</th>
                <th>Data Quitação</th>
                <th>Valor R$</th>
                <th>Status</th>
                <th>Boleto</th>
                <th>Nota Fiscal PDF</th>
                <th>Nota Fiscal XML</th>
              </tr>
            </thead>
            <tbody v-if="boletos.length > 0">
              <tr v-for="dado in boletos" :key="dado.NR_DOCUMENTO">
                <td>{{ dado.NR_DOCUMENTO }}</td>
                <td>{{ dado.DT_EMISSAO }}</td>
                <td><span :class='dado.SITUACAO == "No Prazo" ? "badge badge-success" : (dado.SITUACAO == "Em Atraso" ? "badge badge-error" : "")'>{{ dado.DATA_VENCIMENTO }}</span></td>
                <td>{{ dado.DATA_QUITACAO }}</td>
                <td>{{ dado.VL_PARCELA }}</td>
                <td><span :class='dado.SITUACAO == "No Prazo" ? "badge badge-success" : (dado.SITUACAO == "Em Atraso" ? "badge badge-error" : "badge badge-default")'>{{ dado.SITUACAO == "Em Atraso" ? "Vencida" : dado.STATUS }}
                </span></td>
                <td><a target="_blank" href="#" @click.prevent="download(dado.URL_BOLETO)"><i class="material-icons media-left">file_download</i></a></td>
                <td><a target="_blank" href="#" @click.prevent="download(dado.URL_NF)"><i class="material-icons media-left">file_download</i></a></td>
                <td><a target="_blank" href="#" @click.prevent="download(dado.URL_NF_XML)"><i class="material-icons media-left">file_download</i></a></td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr></tr>
              <tr>
                <td>Sem informações...</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["idGpa"],
  data() {
    return {
      titulo: "Lista de Boletos e Notas Fiscais",
      //boletos: [],
    };
  },
  created() {
    let dados = {
      token: sessionStorage.token,
      idgpa: sessionStorage.idgpa
    };
    this.$store.dispatch("boletos", dados);
  },
  computed: {
    boletos() {
      return this.$store.state.boletos;
    }
  },
  methods: {
    download (url_file) {
      if(url_file) {               
        console.log("URL: " + url_file);
        window.open(url_file, '_blank')
      }
    },
  }
};
</script>
