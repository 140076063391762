import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const state = {
    token: '',
    user: {},
    projetos: {},
    chamados: {},
    boletos: {},
    users: {},
    newUser: {},
    userDeletado: {},
    userUnico: {},
    emailEnviado: {}
}

const actions = {
    login(context, data) {
        axios.post('http://18.232.176.47/api/auth/login', data, {
            withCredentials: false
        }).then(response => (
            context.commit('addToken', response.data)
        ));
    },
    user(context, token) {
        axios.get('http://18.232.176.47/api/user', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then(response => (
            context.commit('user', response.data)
        ));
    },
    /*
    projetos(context, idgpa) {
        axios
            .get('https://app.plataformasintonia.com/api/api/v1/projetosagros/list/null/' + idgpa)
            .then(response => (
                context.commit('projetos', response.data)
            ));
    },
    */
    projetos(context, idgpa) {
        axios
            .get('https://plataformasintonia.com/api/1.1/wf/agro1_APIbusca_projetos_cliente?idgpa=' + idgpa)
            .then(response => (
                context.commit('projetos', response.data)
            ));
    },
    chamados(context, dados) {
        axios.get('http://18.232.176.47/api/chamados/' + dados.idgpa, {
            headers: {
                Authorization: 'Bearer ' + dados.token
            }
        }).then(response => (
            context.commit('chamados', response.data)
        ));
    },
    boletos(context, dados) {
        axios.get('http://18.232.176.47/api/boletos/' + dados.idgpa, {
            headers: {
                Authorization: 'Bearer ' + dados.token
            }
        }).then(response => (
            context.commit('boletos', response.data)
        ));
    },
    users(context, dados) {
        let $url = '';
        if (dados.id) {
            $url = 'http://18.232.176.47/api/users/' + dados.id;
        } else {
            $url = 'http://18.232.176.47/api/users';
        }
        axios.get($url, {
            headers: {
                Authorization: 'Bearer ' + dados.token
            }
        }).then(response => (
            context.commit('users', response.data)
        ));
    },
    userUnico(context, dados) {
        let $url = '';
        if (dados.id) {
            $url = 'http://18.232.176.47/api/users/' + dados.id;
        } else {
            $url = 'http://18.232.176.47/api/users';
        }
        axios.get($url, {
            headers: {
                Authorization: 'Bearer ' + dados.token
            }
        }).then(response => (
            context.commit('userUnico', response.data)
        ));
    },
    newUser(context, data) {
        if (data.id) {
            axios.post('http://18.232.176.47/api/editarUser/' + data.id, data, {
                headers: {
                    Authorization: 'Bearer ' + data.token
                }
            }).then(response => (
                context.commit('newUser', response.data)
            ));
        } else {
            axios.post('http://18.232.176.47/api/auth/registro', data, {
                headers: {
                    Authorization: 'Bearer ' + data.token
                }
            }).then(response => (
                context.commit('newUser', response.data)
            ));
        }
    },
    deleteUser(context, data) {
        axios.post('http://18.232.176.47/api/excluirUser/' + data.id, data, {
            headers: {
                Authorization: 'Bearer ' + data.token
            }
        }).then(response => (
            context.commit('deleteUser', response.data)
        ));
    },
    enviarEmail(context, data) {
        axios.post('http://18.232.176.47/api/reenviarEmail', data, {
            headers: {
                Authorization: 'Bearer ' + data.token
            }
        }).then(response => (
            context.commit('emailUser', response.data)
        ));
    },
}

const mutations = {
    addToken(state, data) {
        state.token = data.token
        state.user = data.user
    },
    user(state, data) {
        state.user = data
    },
    projetos(state, data) {
        state.projetos = data
    },
    chamados(state, data) {
        state.chamados = data
    },
    boletos(state, data) {
        state.boletos = data
    },
    users(state, data) {
        state.users = data
    },
    userUnico(state, data) {
        state.userUnico = data
    },
    newUser(state, data) {
        state.newUser = data
    },
    deleteUser(state, data) {
        state.userDeletado = data
    },
    emailUser(state, data) {
        state.emailEnviado = data
    }
}

const store = new Vuex.Store({
    state,
    actions,
    mutations
})

export default store